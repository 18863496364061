@import url(http://fonts.googleapis.com/css?family=Lato:300);
h1,
h2,
h3,
h4,
h5 {
  text-align: center;
}
button {
  margin-bottom: 0px !important;
}
html {
  width: 100%;
  height: 100%;
}
body {
  width: 100%;
  height: 100%;
}
.cover {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  position: absolute;
  transition: all 2s;
  z-index: 8888;
}
.cover_on {
  opacity: 1;
}
.cover_off {
  opacity: 0;
  z-index: -10;
}
.logo {
  position: absolute;
  border-radius: 400px;
  box-shadow: rgba(0, 0, 0, 0.8) 0px 0 10px;
  transition: all 1s;
  z-index: 9999;
}
.rddc_cover_logo {
  width: 33%;
  left: 10%;
  top: 20%;
}
.cover_logo {
  width: 33%;
  right: 10%;
  top: 20%;
}
.rddc_top_logo {
  top: 10px;
  left: 10px;
  width: 50px;
}
.top_logo {
  top: 10px;
  right: 10px;
  width: 50px;
}
.absolute_center {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  margin: auto;
}
.mapInterface {
  height: 550px;
}
#map {
  height: 500px;
}
.mapContainer {
  width: 100%;
  height: 100%;
}
.tableContainer {
  height: 100%;
  overflow: auto;
}
.row {
  width: 90%;
  margin: auto;
  opacity: 0;
  transition: all 0.5s;
}
.tableWell {
  font-family: 'Lato', sans-serif;
  background: white;
  box-shadow: #000000 3px 3px 5px -3px;
  padding: 10px;
  margin: 0 5px 10px 5px;
}
.country {
  text-align: center;
  cursor: pointer;
}
.country:hover {
  background: whitesmoke;
}
.jvectormap-zoomin {
  display: none;
}
.jvectormap-zoomout {
  display: none;
}
.newsImg {
  width: 100%;
}
.youtube {
  cursor: pointer;
}
.youtube-overlay {
  left: 50%;
  top: 50%;
  z-index: 100;
  position: absolute;
  cursor: pointer;
}
#searchField {
  display: inline !important;
  float: right;
  width: 50%;
}
